import React, {useRef, useState, useEffect} from 'react';
import {WidgetProps} from '../common';
import css from './PulsatingCircleWidget.module.scss';
import {FontOption} from '../../controls/FontPicker/FontPicker';
import {findRangeForPulse} from '../../utils/rangeUtils';
import {loadCustumFont} from '../../utils/fontUtils';
import Animation, {
  AnimationRef,
  calculateAnimationDuration,
} from '../../utils/animation';

const DEFAULT_SHAPE_COLOR = '#fff';
export const DEFAULT_PULSATING_CIRLCE_WIDGET_RANGES = [
  {
    from: 40,
    to: 70,
    shapeColor: '#00ff00',
  },
  {
    from: 71,
    to: 100,
    shapeColor: '#ffF000',
  },
  {
    from: 81,
    to: 999,
    shapeColor: '#ff0000',
  },
];

export type PulsatingCircleWidgetProps = WidgetProps<
  {
    shapeColor?: string;
  },
  {
    shouldSyncColors?: boolean;
    textStyles?: {font?: FontOption; color?: string};
    additionalText?: {value?: string};
  }
>;

export const PulsatingCircleWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: PulsatingCircleWidgetProps) => {
  const shouldShowEmptyState = emptyStateComponent && !pulse;
  const textColor = configuration.textStyles.color;
  const textFont = configuration.textStyles.font;
  const range = findRangeForPulse(
    configuration?.ranges || DEFAULT_PULSATING_CIRLCE_WIDGET_RANGES,
    pulse
  );
  const shapeColor = range?.shapeColor || DEFAULT_SHAPE_COLOR;
  const shouldSyncColors = configuration.shouldSyncColors;
  const additionalText = configuration.additionalText;

  const [animationDuration, setAnimationDuration] = useState(1000);

  const strokeWidthAnimationRef = useRef<AnimationRef | null>(null);
  const opacityAnimationRef = useRef<AnimationRef | null>(null);
  const radiusAnimationRef = useRef<AnimationRef | null>(null);
  const radiusMainAnimationRef = useRef<AnimationRef | null>(null);

  const durationRef = useRef(animationDuration);

  const restartAllAnimations = () => {
    strokeWidthAnimationRef.current?.restartAnimation();
    opacityAnimationRef.current?.restartAnimation();
    radiusAnimationRef.current?.restartAnimation();
    radiusMainAnimationRef.current?.restartAnimation();
  };

  useEffect(() => {
    durationRef.current = calculateAnimationDuration(pulse, 1.5);
  }, [pulse]);

  useEffect(() => {
    if (textFont) {
      loadCustumFont(textFont);
    }
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        margin: '0',
        padding: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        top: '0',
        left: '0',
        fontSize: '50px',
      }}
    >
      <svg
        viewBox="0 0 200 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: 'auto',
          display: 'block',
        }}
      >
        <circle cx="50%" cy="30%" r="30" fill="none" stroke={shapeColor}>
          <Animation
            ref={strokeWidthAnimationRef}
            animationDuration={animationDuration}
            values="6; 0;"
            attributeName="stroke-width"
            restart="whenNotActive"
            onAnimationEnd={() => {
              setAnimationDuration(durationRef.current);
              restartAllAnimations();
            }}
          />
          <Animation
            ref={opacityAnimationRef}
            animationDuration={animationDuration}
            values="0.7; 0.3; 0;"
            attributeName="opacity"
          />
          <Animation
            ref={radiusAnimationRef}
            animationDuration={animationDuration}
            values="30; 45; 53; 60;"
            attributeName="r"
          />
        </circle>

        <circle cx="50%" cy="30%" r="30" fill={shapeColor}>
          <Animation
            ref={radiusMainAnimationRef}
            animationDuration={animationDuration}
            values="30; 34; 33; 32; 31; 30; 30; 30; 30; 30; 30; 30; 30; 30; 30; 30; 30; 30; 30;"
            attributeName="r"
          />
        </circle>
        <text
          fill={shouldSyncColors ? shapeColor : textColor}
          dominantBaseline="middle"
          textAnchor="middle"
          x="49%"
          y="80%"
          className={css.pulse}
          style={{fontFamily: `"${textFont?.name || 'Inter'}"`}}
        >
          {pulse}
        </text>
        <text
          fill={shouldSyncColors ? shapeColor : textColor}
          dominantBaseline="middle"
          textAnchor="middle"
          x="50%"
          y="93%"
          className={css.text}
          style={{fontFamily: `"${textFont?.name || 'Inter'}"`}}
        >
          {additionalText.value}
        </text>
      </svg>
    </div>
  );
};
