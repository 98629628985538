// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Inter\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Inter-VariableFont_slnt_Valorant.ttf\") format(\"opentype\")}.R5QCPBBoGd459MhwWD4y{font-size:44px;font-family:\"Inter\";font-weight:700;font-feature-settings:\"tnum\";letter-spacing:-3px}.gzl8Exz0nkInasaZ9IGA{font-size:16px;font-family:arial;font-weight:700}", "",{"version":3,"sources":["webpack://./src/widgets/PulsatingCircleWidget/PulsatingCircleWidget.module.scss"],"names":[],"mappings":"AAAA,WACE,mBAAA,CACA,2IAAA,CAIF,sBACE,cAAA,CACA,mBAAA,CACA,eAAA,CACA,4BAAA,CACA,mBAAA,CAGF,sBACE,cAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":["@font-face {\n  font-family: 'Inter';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Inter-VariableFont_slnt_Valorant.ttf')\n    format('opentype');\n}\n\n.pulse {\n  font-size: 44px;\n  font-family: 'Inter';\n  font-weight: 700;\n  font-feature-settings: 'tnum';\n  letter-spacing: -3px;\n}\n\n.text {\n  font-size: 16px;\n  font-family: arial;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "R5QCPBBoGd459MhwWD4y",
	"text": "gzl8Exz0nkInasaZ9IGA"
};
export default ___CSS_LOADER_EXPORT___;
