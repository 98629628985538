import React, {useRef, useState, useEffect} from 'react';
import {WidgetProps} from '../common';

export type MaxWidgetProps = WidgetProps<
  {},
  {
    fontColor: string;
    backgroundColor: string;
    font: string;
    version: string;
  }
>;

const LibMigrated = 'LibMigrated';

export const MaxWidget = ({
  pulse,
  fromLaunch,
  configuration,
}: MaxWidgetProps) => {
  const [maxBPM, setMaxBPM] = useState(pulse);

  useEffect(() => {
    pulse > maxBPM && setMaxBPM(pulse);
  }, [pulse]);

  return (
    <div
      style={{
        position: 'relative',
        margin: '0',
        padding: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent:
          configuration.version === LibMigrated ? 'center' : 'start',
        alignItems: configuration.version === LibMigrated ? 'center' : 'start',
        overflow: 'hidden',
        top: '0',
        left: '0',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: 'auto',
          display: 'block',
        }}
        viewBox="0 0 530 300"
      >
        <foreignObject
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <div
            style={{
              display: 'flex',
              justifyContent:
                configuration.version === LibMigrated ? 'center' : 'start',
              fontSize: '180px',
              backgroundColor: fromLaunch
                ? configuration.backgroundColor
                : 'transparent',
              color: configuration.fontColor,
              fontFamily: "'" + configuration.font + "'",
            }}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${configuration.font}`}
              rel={'stylesheet'}
            />
            {maxBPM}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};
