import React, {useRef, useState, useEffect} from 'react';
import {WidgetProps} from '../common';
import {motion} from 'framer-motion';
import {PulseSpeedStrategy, findRangeForPulse} from '../../utils/rangeUtils';

interface AnimationObject {
  strategy: PulseSpeedStrategy;
  animationKey: string;
  pulseDuration: number;
}

export type EcgHeartWidgetProps = WidgetProps<
  {},
  {
    thickness: string;
    lineColor: string;
    version: string;
  }
>;

const LibMigrated = 'LibMigrated';

export const EcgHeartWidget = ({pulse, configuration}: EcgHeartWidgetProps) => {
  let strategy = new PulseSpeedStrategy();
  const pathRef = useRef(null);
  strategy.add(0, 70, 1.5);
  strategy.add(71, 90, 1.2);
  strategy.add(91, 100, 1);
  strategy.add(101, 115, 0.8);
  strategy.add(116, 1000, 0.7);
  const [animationState, setAnimationState] = useState<AnimationObject>({
    strategy: strategy,
    animationKey: String(Date.now()),
    pulseDuration: 0.5,
  });

  const animationDone = () => {
    setAnimationState({
      ...animationState,
      animationKey: String(Date.now()),
      pulseDuration: animationState.strategy.get(pulse),
    });
  };

  return (
    <div
      style={{
        position: 'relative',
        margin: '0',
        padding: '0',
        height: configuration.version === LibMigrated ? '100%' : '281px',
        alignItems: configuration.version === LibMigrated ? 'center' : 'start',
        justifyContent:
          configuration.version === LibMigrated ? 'center' : 'start',
        width: configuration.version === LibMigrated ? '100%' : '312px',
        display: 'flex',
        overflow: 'hidden',
        top: '0',
        left: '0',
      }}
    >
      <svg
        viewBox="0 0 312 281"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: 'auto',
          display: 'block',
        }}
      >
        <motion.path
          d={
            'm 4.2,200.5 c 0,0 45.4,3.2 62.2,-2 2.9,-0.9 4.04,-5.7 6,-8 1.4,-1.6 2.9,-4.7 5,-5 2.2,-0.3 5.8,3.5 7.2,5.2 2,2.5 5.2,9 5.9,9.4 2.6,1.7 14.9,1.3 14.9,1.3 l 6.5,29.6 9,-226.5 6,258 8.9,-61.5 c 0,0 30,2.1 41.1,-3 6,-2.8 10.4,-11.4 14.5,-16.5 6.2,-7.8 11.5,-21 19.5,-27 2.4,-1.8 6.5,-3.5 9.5,-3.5 2.9,0.02 6.7,1.7 9,3.5 7.06,5.4 12.5,16.6 17.5,24 3,4.4 7,10.5 10.3,14.5 2.02,2.4 4.6,6.8 7.6,7.6 6.4,1.8 15.7,0.6 22.3,0.6 3.6,0 15.9,-0.3 15.9,-0.3'
          }
          ref={pathRef}
          style={{
            strokeWidth: configuration.thickness + 'px',
            stroke: configuration.lineColor,
            fill: 'none',
            strokeLinecap: 'round',
          }}
          key={animationState.animationKey}
          initial={{pathLength: 0}}
          onAnimationComplete={() => animationDone()}
          animate={{pathLength: 1}}
          transition={{duration: animationState.pulseDuration}}
        />
      </svg>
    </div>
  );
};
