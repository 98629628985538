import React from 'react';
import {
  BpmWidget,
  ChristmasWidget,
  DeadByDaylightWidget,
  DeadSpaceWidget,
  SpeedTestWidget,
  GaugeWidget,
  BeatSaberWidget,
  GlowyWidget,
  DiabloWidget,
  ResidentEvilWidget,
  UnicornWidget,
  AnimeBarWidget,
  AlanWakeWidget,
  JumpScareCounterWidget as JumpScareCounterWidgetV2,
  AloneInTheDarkWidget,
  FalloutWidget,
  StarWarsWidget,
  ValorantWidget as ValorantWidgetV2,
  EldenRingWidget,
  SilentHill2Widget,
  PredictionWidget,
  Stalker2Widget,
  MetaPulsatingWidget,
  PulsatingCircleWidget,
  EcgHeartWidget as EcgHeartWidgetMigrated,
  MaxWidget as MaxWidgetMigrated,
  BpmPercentageWidget as BpmPercentageWidgetMigrated,
} from '@pulsoid-private/widgets-lib';
import PulsoidClient from './PulsoidClient';
import Websocket from 'react-websocket';
import {CaloriesWidget} from './widgets/CaloriesWidget';
import {PixelHeartWidget} from './widgets/PixelHeartWidget';
import {RealHeartWidget} from './widgets/RealHeartWidget';
import {PictureWidget} from './widgets/PictureWidget';
import {SoundWidget} from './widgets/SoundWidget';
import {HeartAnimationWidget} from './widgets/HeartAnimationWidget';
import {RealTimeGraphWidget} from './widgets/RealTimeGraphWidget';
import {ValorantWidget} from './widgets/ValorantWidget';
import {TextWidget} from './widgets/TextWidget';
import {VideoWidget} from './widgets/VideoWidget';
import {FitnessZoneWidget} from './widgets/FitnessZoneWidget';
import {PulsoidMedicalWidget} from './widgets/PulsoidMedicalWidget';
import {StromnoWidget} from './widgets/StromnoWidget';
import {CyberpunkHeartWidget} from './widgets/CyberpunkHeartWidget';
import {WindowsNT} from './widgets/WindowsNT';
import {BeatsSaberWidget} from './widgets/BeatsSaberWidget';
import {PulsoidStromnoWidget} from './widgets/PulsoidStromnoWidget';
import {GeometryDashWidget} from './widgets/GeometryDashWidget';
import {DeathloopWidget} from './widgets/DeathloopWidget';
import {HalloweenMoonWidget} from './widgets/HalloweenMoonWdiget';
import {SoundWidgetV2} from './widgets/SoundWidgetV2';
import {JumpScareCounterWidget} from './widgets/JumpScareCounterWidget';
import {RealTimeGraphWidgetV2} from './widgets/RealTimeGraphWidgetV2';
import {MinecraftWidget} from './widgets/MinecraftWidget';
import {ReferralWidget} from './widgets/ReferralWidget';

import {
  MetaPredictionWidget,
  MetaStalkerWidget,
} from '@pulsoid-private/widgets-lib';

export default class WidgetContainer extends React.Component {
  constructor(props) {
    super(props);
    this.client = new PulsoidClient();
    let path = window.location.pathname.split('/');
    this.state = {
      widgetId: path.pop() || path.pop(),
      isFromLaunch: window.location.href.indexOf('fromLaunch') > -1,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    this.isTest = searchParams.get('test') === 'true';
    this.isQA = searchParams.get('qa') === 'true';
    this.testHeartRate = this.isTest && searchParams.get('heartRate');
    this.migratedWidget = searchParams.get('migratedWidget') === 'true';
    this.libMigratedVersion = searchParams.get('libMigratedVersion') === 'true';

    if (this.testHeartRate) {
      this.setState({heartRate: Number(this.testHeartRate)});
    }

    this.client
      .getWidget(this.state.widgetId)
      .then((s) => {
        this.setState({
          wsListen: this.isTest
            ? 'wss://ramiel2.pulsoid.net/listen/7f20184a-5fcf-11e6-8b77-86f30ca893d3'
            : s.ramielUrl,
          configuration: s.configuration,
          metaWidgetId: s.metaId,
          token: s.token,
        });
      })
      .catch(this.handleErrors);
  }

  handleErrors = async () => {};

  handleData = (pulse) => {
    let data = JSON.parse(pulse).data;
    if (data.heartRate === 0) {
      return;
    }
    this.setState({heartRate: data.heartRate});
  };

  render() {
    let widget;
    if (this.state.metaWidgetId) {
      if (this.state.metaWidgetId === 'c59b65e8-1c8b-4af2-b8f2-fd22fa9062a4') {
        let version = this.libMigratedVersion ? {version: 'LibMigrated'} : {};
        widget = (
          <EcgHeartWidgetMigrated
            pulse={this.state.heartRate}
            configuration={{...this.state.configuration, ...version}}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'f8a131ce-6a47-4fc8-a1af-631a4d97644b' ||
        this.state.metaWidgetId === 'bf6bdf21-10a7-4a28-97ec-a239e6c77f8b' ||
        this.state.metaWidgetId === '01baa99c-307d-44ca-bd7a-6b5eed40d664'
      ) {
        widget = (
          <BpmWidget
            pulse={this.state.heartRate}
            configuration={{
              ...this.state.configuration,
              backgroundColor: this.state.isFromLaunch
                ? this.state.configuration.backgroundColor
                : 'transparent',
            }}
          />
        );
      } else if (
        this.state.metaWidgetId === '7958fba1-53e5-4da6-b15b-4789cecd148f'
      ) {
        // TODO: Move to the upper if after removing the old addvanced widget
        widget = (
          <BpmWidget
            pulse={this.state.heartRate}
            configuration={{
              ...this.state.configuration,
              backgroundColor: this.state.isFromLaunch
                ? this.state.configuration.backgroundColor
                : 'transparent',
            }}
          />
        );
      } else if (
        this.state.metaWidgetId === 'dddd6ad4-2d4d-4cb4-a36b-cf255fb50002'
      ) {
        widget = (
          <GeometryDashWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '085f1f88-d599-4857-89b3-d84c4ed665b8'
      ) {
        widget = (
          <PixelHeartWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '7a78ac9f-a682-46b5-ab6c-606ee631bbb2'
      ) {
        widget = (
          <CaloriesWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '55af6abf-e378-4c9d-84bf-bc7565fafaf5'
      ) {
        widget = (
          <RealHeartWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '18fd6009-c908-4e6e-a461-920108cd2998'
      ) {
        widget = (
          <PictureWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '8549a7b5-e0b4-4149-b60e-d52ca0462c93'
      ) {
        widget = (
          <SoundWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'bc5ba654-fcff-4ab8-b446-182d111068ba'
      ) {
        widget = (
          <HeartAnimationWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'bbffeb93-7d72-4afb-a99b-37916f057810'
      ) {
        widget = (
          <RealTimeGraphWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'b51157e8-204c-4923-b55a-a8a417de14e1'
      ) {
        widget = (
          <RealTimeGraphWidgetV2
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'b8bb76b6-28be-4216-a45e-c8eff31ec547'
      ) {
        let version = this.libMigratedVersion ? {version: 'LibMigrated'} : {};
        widget = (
          <MaxWidgetMigrated
            pulse={this.state.heartRate}
            configuration={{...this.state.configuration, ...version}}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '9ac10e79-4da7-47ab-85c4-32665f740521'
      ) {
        widget = (
          <ValorantWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'c87d7887-c3e2-487b-b04d-dab540261f10'
      ) {
        widget = (
          <TextWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'e3e94f6f-60e8-490b-a670-5e62abe0d241'
      ) {
        widget = (
          <VideoWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'b92399a1-8516-49df-8dd4-70bd6010ade0'
      ) {
        widget = (
          <FitnessZoneWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '487a7270-cd96-40c8-951e-5c39024526a9'
      ) {
        widget = (
          <PulsoidMedicalWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '8162cd92-90cb-4454-86d5-03bca4cab4e9'
      ) {
        widget = (
          <StromnoWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'ad65cdc6-5c4f-476c-980f-68fc460776a3'
      ) {
        let version = this.libMigratedVersion ? {version: 'LibMigrated'} : {};
        widget = (
          <BpmPercentageWidgetMigrated
            pulse={this.state.heartRate}
            configuration={{...this.state.configuration, ...version}}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '143da3c1-3c62-4959-9a76-a660a83f3871'
      ) {
        widget = (
          <CyberpunkHeartWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'b828337f-cdb0-49d2-8e55-9b1285cd0d22'
      ) {
        widget = (
          <WindowsNT
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '45d0787f-f6be-4176-8076-6e39d2893868'
      ) {
        widget = (
          <GaugeWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '2654f68c-39e5-4fe6-bf7f-5f8ee82b4808'
      ) {
        widget = (
          <BeatsSaberWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '7d8bec8f-9505-4305-bb1e-611dd9ee1760'
      ) {
        widget = (
          <PulsoidStromnoWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'f0654a12-3a59-4beb-b567-c5febda19c48'
      ) {
        widget = (
          <BeatSaberWidget
            pulse={this.state.heartRate}
            configuration={{
              ...this.state.configuration,
              withHeart: this.state.configuration?.heartEnabled,
            }}
          />
        );
      } else if (
        this.state.metaWidgetId === '3c455966-f232-4383-b271-8aaae10dc6c5'
      ) {
        widget = (
          <DeathloopWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'a9a9b2b7-4084-4865-8ab8-3602290053c9'
      ) {
        widget = (
          <HalloweenMoonWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '0eca0651-08ce-43d3-87db-0ac07f2a2122'
      ) {
        widget = (
          <SoundWidgetV2
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '74979489-b18d-4916-9fa5-13578413579d'
      ) {
        widget =
          this.state.configuration?.version === 2 ? (
            <JumpScareCounterWidgetV2
              pulse={this.state.heartRate}
              configuration={this.state.configuration}
            />
          ) : (
            <JumpScareCounterWidget
              pulse={this.state.heartRate}
              configuration={this.state.configuration}
              fromLaunch={this.state.isFromLaunch}
            />
          );
      } else if (
        this.state.metaWidgetId === '024bead1-8cbe-4cff-9c22-da43cd564ef0'
      ) {
        widget = (
          <GlowyWidget
            pulse={this.state.heartRate}
            configuration={{
              ...this.state.configuration,
              textColor: this.state.configuration?.bpmColor,
            }}
          />
        );
      } else if (
        this.state.metaWidgetId === '09ffc325-8c85-4464-b191-e5982c4f2c46'
      ) {
        widget = (
          <MinecraftWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === '01035006-495e-42b1-8785-31b3eab8f46a'
      ) {
        widget = (
          <ReferralWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
            fromLaunch={this.state.isFromLaunch}
          />
        );
      } else if (
        this.state.metaWidgetId === 'e3a6a781-3bbc-471c-bc52-9d98c89b42a7'
      ) {
        widget = (
          <ResidentEvilWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '36e6464c-0508-409b-8e1d-336a70dced0d'
      ) {
        widget = (
          <DeadByDaylightWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === 'd938e320-e758-48c5-808f-f9e104fb833b'
      ) {
        widget = (
          <DeadSpaceWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '128a8629-f77c-4ecb-a432-d4e059dcd616'
      ) {
        widget = (
          <ChristmasWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === 'a19dec49-0566-423b-9b2a-22c7da7f52ce'
      ) {
        widget = (
          <SpeedTestWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === 'd666c1ad-0249-11ee-be56-0242ac120002'
      ) {
        widget = (
          <DiabloWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '5594c58e-d11c-4013-bc6d-884f5e371461'
      ) {
        widget = (
          <UnicornWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '45f9cc8c-d063-481a-9bd9-c5c632146c39'
      ) {
        widget = (
          <AnimeBarWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '5f75d534-40a8-4809-9260-c11ffd1f368b'
      ) {
        widget = (
          <AlanWakeWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '5d8ee005-12ec-40b9-a052-9b60137709a1'
      ) {
        widget = (
          <FalloutWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '12db780a-72cc-419a-980b-ea082c4b168d'
      ) {
        widget = (
          <StarWarsWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '627b1fba-9ab1-4d43-afb4-8f78281432f0'
      ) {
        widget = (
          <ValorantWidgetV2
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '4e58c40b-d5bd-4129-9117-46b385b941e4'
      ) {
        widget = (
          <EldenRingWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '8179164d-de75-4761-afa2-c3b3dbc8dfac'
      ) {
        widget = (
          <AloneInTheDarkWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (
        this.state.metaWidgetId === '2d1e8e9d-c271-4580-b28c-0012d7e07be1'
      ) {
        widget = (
          <SilentHill2Widget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (this.state.metaWidgetId === MetaPredictionWidget) {
        widget = (
          <PredictionWidget
            token={this.state.token}
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (this.state.metaWidgetId === MetaStalkerWidget) {
        widget = (
          <Stalker2Widget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else if (this.state.metaWidgetId === MetaPulsatingWidget) {
        widget = (
          <PulsatingCircleWidget
            pulse={this.state.heartRate}
            configuration={this.state.configuration}
          />
        );
      } else {
        widget = <div>placeholder</div>;
      }
      widget = (
        <div className={'fill-window'} id={'view_widget_container'}>
          {this.state.wsListen && !this.testHeartRate && (
            <Websocket
              reconnectIntervalInMilliSeconds={3000}
              url={this.state.wsListen}
              onMessage={this.handleData.bind(this)}
            />
          )}
          {this.state.heartRate && widget}
        </div>
      );
    } else {
      widget = <div />;
    }

    return widget;
  }
}
