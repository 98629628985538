import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';

export const calculateAnimationDuration = (
  pulse: number,
  speedFactor: number = 1
): number => {
  const duration = (60 / pulse) * 1000;
  const normalizedDuration = Math.max(Math.ceil(duration), 300);
  return normalizedDuration * speedFactor;
};

export interface AnimationProps {
  animationDuration: number;
  onAnimationEnd?: () => void;
  values: string;
  keySplines?: string;
  keyTimes?: string;
  attributeName: string;
  additive?: 'replace' | 'sum';
  restart?: 'always' | 'whenNotActive' | 'never';
}

export interface AnimationRef {
  restartAnimation: () => void;
}

const Animation = forwardRef<AnimationRef, AnimationProps>(
  (
    {
      animationDuration,
      onAnimationEnd,
      values,
      keyTimes,
      keySplines,
      attributeName,
      restart = 'whenNotActive',
    },
    ref
  ) => {
    const animateRef = useRef<SVGAnimateElement | null>(null);

    useEffect(() => {
      const animateElement = animateRef.current;

      const handleAnimationEnd = () => {
        if (onAnimationEnd) {
          onAnimationEnd();
        }
      };

      if (animateElement) {
        animateElement.addEventListener('endEvent', handleAnimationEnd);
      }

      return () => {
        if (animateElement) {
          animateElement.removeEventListener('endEvent', handleAnimationEnd);
        }
      };
    }, [onAnimationEnd]);

    useImperativeHandle(ref, () => ({
      restartAnimation: () => {
        if (animateRef.current) {
          animateRef.current.beginElement();
        }
      },
    }));

    return (
      <animate
        ref={animateRef}
        attributeName={attributeName}
        values={values}
        keySplines={keySplines}
        keyTimes={keyTimes}
        dur={`${animationDuration}ms`}
        restart={restart}
      />
    );
  }
);

export default Animation;
