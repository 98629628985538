import React, {useRef, useState, useEffect} from 'react';
import {WidgetProps} from '../common';

export type BpmPercentageWidgetProps = WidgetProps<
  {},
  {
    max: number;
    allowOver100: boolean;
    fontColor: string;
    font: string;
    alignment: string;
    version: string;
  }
>;

const LibMigrated = 'LibMigrated';

export const BpmPercentageWidget = ({
  pulse,
  configuration,
}: BpmPercentageWidgetProps) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (pulse > configuration.max) {
      if (configuration.allowOver100) {
        setPercentage(Math.floor((pulse * 100) / configuration.max));
      } else {
        setPercentage(100);
      }
    } else {
      setPercentage(Math.floor((pulse * 100) / configuration.max));
    }
  }, [pulse]);

  return (
    <div
      style={{
        position: 'relative',
        margin: '0',
        padding: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent:
          configuration.alignment === 'left'
            ? 'start'
            : configuration.alignment === 'center'
            ? 'center'
            : configuration.alignment === 'right'
            ? 'end'
            : 'start',
        alignItems: configuration.version === LibMigrated ? 'center' : 'start',
        overflow: 'hidden',
        top: '0',
        left: '0',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: 'auto',
          display: 'block',
        }}
        viewBox={
          configuration.version === LibMigrated ? '0 0 470 300' : '0 0 330 300'
        }
      >
        <foreignObject
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <div
            style={{
              display: 'flex',
              justifyContent:
                configuration.alignment === 'left'
                  ? 'start'
                  : configuration.alignment === 'center'
                  ? 'center'
                  : configuration.alignment === 'right'
                  ? 'end'
                  : 'start',
              fontSize:
                configuration.version === LibMigrated ? '120px' : '60px',
              color: configuration.fontColor,
              fontFamily: "'" + configuration.font + "'",
            }}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${configuration.font}`}
              rel={'stylesheet'}
            />
            {percentage}%
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};
